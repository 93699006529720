html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  display: flex;
  justify-content: center;
  width: 100%;
}

body {
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1032px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f34f41;
}

#santa {
  width: 400px;
  position: fixed;
  bottom: 0;
  right: 50px;

  @media screen and (max-width: 768px) {
    width: 300px;
  }

  @media screen and (max-width: 576px) {
    width: 60%;
    right: 25px;
    bottom: -15px;
  }
}

#snow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.snowflake {
  --size: 1vw;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
}

@for $i from 1 through 500 {
  .snowflake:nth-child(#{$i}) {
    --size: #{random(4) * 2}px;
    --left-ini: #{random(20) - 10}vw;
    --left-end: #{random(20) - 10}vw;
    left: #{random(100)}vw;
    animation: snowfall #{12 + random(10)}s linear infinite;
    animation-delay: -#{random(10)}s;
  }
}

/* added small blur every 6 snowflakes*/
.snowflake:nth-child(6n) {
  filter: blur(1px);
}

.git-link {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: white;

  &:hover {
    color: white;
    font-style: italic;
  }

  @media screen and (max-width: 900px) {
    max-width: 30%;
  }
}
