.modal-content {
  padding: 2.5rem 1rem 2rem;
  text-align: center;
}

.modal-close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.copy-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #03c03c;
  opacity: 0.8;
  color: white;
  font-size: 1rem;
  height: 2rem;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

h2 {
  margin-bottom: 1.5rem;
}

#pairs-data {
  display: flex;
  flex-direction: column;
  align-items: center;

  .pair-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  h3 {
    display: inline-block;
    margin-bottom: 0;
    line-height: 1;
    margin-right: 0.5rem;
  }

  button {
    color: white;
    background: #03c03c;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    line-height: 2;
    cursor: pointer;
  }
}

#csv-button {
  color: white;
  background: #03c03c;
  height: 3rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  line-height: 2;
  cursor: pointer;
  margin-top: 1rem;
}
