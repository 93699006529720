.organizer-container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.pairs-container {
  position: absolute;
  width: 100%;
  top: 25%;
  transform: translate(0, -50%);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.5rem;
  max-height: 45vh;
  overflow-y: auto;

  div {
    background: white;
    padding: 1rem;
    border-radius: 4px;
    position: relative;

    .name {
      font-size: 2rem;
      margin: 0;
    }

    .preferance {
      margin: 0;
      margin-top: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      /* number of lines to show */
      -webkit-box-orient: vertical;
      word-break: break-word;
    }

    .last-assigned {
      margin: 0;
    }

    button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      border: none;
      background: transparent;
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
}

form {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: space-between;

  .input-container {
    width: 85%;
    display: flex;
    justify-content: space-between;

    input {
      height: 2.5rem;
      padding: 0 1rem;
      border-radius: 0.25rem;
      border: none;
      width: 32%;
      font-size: 1.25rem;
    }
  }

  button {
    width: 13%;
    border: none;
    border-radius: 4px;
    background: #03c03c;
    font-size: 1.25rem;
    line-height: 1.25;
    color: white;
    cursor: pointer;
  }
}

#button-container {
  position: absolute;
  top: 58%;
  transform: translate(0, -50%);
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    color: white;
    background: #03c03c;
    height: 3rem;
    border: none;
    border-radius: 4px;
    font-size: 1.5rem;
    line-height: 2;
    cursor: pointer;
  }

  #import-button {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

